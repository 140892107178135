import React, {useEffect} from 'react';
import './App.css';
import {API_URL, COLORS, LIMITS, UNITS, MAX_TIME_DISTANCE_DAYS, DEFAULT_TIME_DISTANCE_DAYS} from './config';
import {Measurement, WeatherStation} from './data_types';
import Plot from 'react-plotly.js';
import {Data, Layout} from "plotly.js";
import DatePicker from "react-datepicker";
import Select from "react-dropdown-select";
import "react-datepicker/dist/react-datepicker.css";

import {min} from "date-fns";

type WeatherDataProps = {
  id: string,
  start_time: Date,
  end_time: Date
};

function StationView() {
  const [stations, setStations] = React.useState<WeatherStation[]>([])
  const [selectedStaton, setSelectedStation] = React.useState<{"label": string, "value": string}>({'label': 'Altnau Hafen in Altnau', 'value': 'altnau-1'})

  var delta_milliseconds_max = MAX_TIME_DISTANCE_DAYS * 86400000;
  var delta_milliseconds_default = DEFAULT_TIME_DISTANCE_DAYS * 86400000;
  var max_end_date = new Date(Date.now())
  var default_start_date = new Date(max_end_date)

  default_start_date.setSeconds(default_start_date.getSeconds() - DEFAULT_TIME_DISTANCE_DAYS * 86400);

  const [endDateTime, setEndDateTime] = React.useState<Date>(max_end_date);
  const [startDateTime, setStartDateTime] = React.useState<Date>(default_start_date)

  function update_start_date(new_start_date: Date) {
      if (new_start_date.getTime() <= endDateTime.getTime()) {
        if (new_start_date.getTime() <= endDateTime.getTime() - delta_milliseconds_max) {
            var new_end_date = new Date(new_start_date.getTime() + delta_milliseconds_max)
            if (new_end_date.getTime() <= max_end_date.getTime()) {
                setEndDateTime(new_end_date);
            }
            else {
                setEndDateTime(max_end_date);
            }
        }
        setStartDateTime(new_start_date);
      }
      else {
          if (new_start_date.getTime() < max_end_date.getTime()) {
              setStartDateTime(new_start_date);
              setEndDateTime(min([new Date(new_start_date.getTime() + delta_milliseconds_default), max_end_date]));
          }
      }
  }

  function update_end_date(new_end_date: Date) {
    if (new_end_date > max_end_date) {
        new_end_date = max_end_date;
    }
    if (new_end_date <= startDateTime) {
        var new_start_date: Date = new Date(new_end_date.getTime() - delta_milliseconds_default);
        setStartDateTime(new_start_date);
        setEndDateTime(new_end_date);
    }
    else if (new_end_date.getTime() - delta_milliseconds_max > startDateTime.getTime()) {
        setStartDateTime(new Date(new_end_date.getTime() - delta_milliseconds_max));
        setEndDateTime(new_end_date);
    }
    else {
        setEndDateTime(new_end_date);
    }
  }

  const handleStationChange = (value: {'label': string, 'value': string}[]) => {
      console.log("Value change to ", value)
      setSelectedStation(value[0])
  };

  useEffect(() => {
      fetch(API_URL + 'weather_station/')
        .then(res => res.json())
        .then(
          (result) => {
            setStations(result)
          },
          (error) => {
            // Todo: handle error
          }
        );
  }, [setStations]);

  let default_option = [
      {'label': '--------', 'value': 'unselected'},
  ]

  let options_1 = stations.map((station) => {
      return {
          "label": station.name + " in " + station.city,
          "value": station.id
      };
  })

  let options = default_option.concat(options_1);

  // console.log(options)
  console.log("Selectable options: ")
  console.log(options)

  if (selectedStaton['value'] !== 'unselected' || selectedStaton == null) {
      return (
          <div>
            <Select options={options} values={[selectedStaton]} onChange={handleStationChange} />
            <div className='flexbox-container dateselector-box'>
              <div className='flexbox-container'>
                <text className='date-selector-text-field'>Select Start Date: </text>
                <DatePicker onChange={(date: Date) => update_start_date(date)} selected={startDateTime} showTimeSelect dateFormat='yyyy-MM-dd HH:MM:SS' />
              </div>
              <div className='flexbox-container'>
                <text className='date-selector-text-field'>Select End Date: </text>
                <DatePicker onChange={(date: Date) => update_end_date(date)} selected={endDateTime} showTimeSelect dateFormat='yyyy-MM-dd HH:MM:SS' />
              </div>
            </div>
            <WeatherElement id={selectedStaton['value']} start_time={startDateTime} end_time={endDateTime}/>
          </div>
      )
  }
  else {
      return (
          <div>
            <Select options={options} values={[selectedStaton]} onChange={handleStationChange} />
            <div className='flexbox-container dateselector-box'>
              <div className='flexbox-container'>
                <text className='date-selector-text-field'>Select Start Date: </text>
                <DatePicker onChange={(date: Date) => update_start_date(date)} selected={startDateTime} showTimeSelect dateFormat='yyyy-MM-dd HH:MM:SS' />
              </div>
              <div className='flexbox-container'>
                <text className='date-selector-text-field'>Select End Date: </text>
                <DatePicker onChange={(date: Date) => update_end_date(date)} selected={endDateTime} showTimeSelect dateFormat='yyyy-MM-dd HH:MM:SS' />
              </div>
            </div>
          </div>
      )
  }
};

function WeatherElement({ id, start_time, end_time }: WeatherDataProps) {
  return (
    <div className="div-weather-element">
        <div className='weather-plot-div'>
            <WeatherPlot id={id} start_time={start_time} end_time={end_time} />
        </div>
    </div>
  );
};


function WeatherPlot({ id, start_time, end_time }: WeatherDataProps) {
  const [weatherData, setWeatherData] = React.useState<Measurement[]>([])

  useEffect(() => {
      var url = API_URL + 'measurement/' + id + '/aggregated/?start_time=' + start_time.toISOString() + '&end_time=' + end_time.toISOString() + '&aggregation_interval_seconds=' + 60
      // var url = API_URL + 'station_measurements/' + uuid + '/?start_time=' + start_time.toISOString() + '&end_time=' + end_time.toISOString()
      fetch(url)
         .then(res => res.json())
         .then(
           (result) => {
             setWeatherData(result)
           },
           (error) => {
             // Todo: handle error
             console.log(error)
           }
         );
  }, [setWeatherData, id, start_time, end_time])

  let time_stamps: string[] = weatherData.map((wd) => {return wd.time_stamp});
  let air_temperature: number[] = weatherData.map((wd) => {return wd.air_temperature});
  let water_temperature: number[] = weatherData.map((wd) => {return wd.water_temperature});
  let wind_speed: number[] = weatherData.map((wd) => {return wd.wind_speed});
  let wind_direction: number[] = weatherData.map((wd) => {return wd.wind_direction});
  let gusts_of_wind: number[] = weatherData.map((wd) => {return wd.gusts_of_wind_speed});
  let air_pressure: number[] = weatherData.map((wd) => {return wd.air_pressure});
  let precipitation_amount: number[] = weatherData.map((wd) => {return wd.precipitation_amount});
  let dewpoint: number[] = weatherData.map((wd) => {return wd.dew_point});
  let radiation: number[] = weatherData.map((wd) => {return wd.solar_radiation});

  let time_stamps_date: Date[] = time_stamps.map((elem) => {return new Date(elem)});

  var air_temperature_trace: Data = {
      x: time_stamps_date,
      y: air_temperature,
      name: "Air Temperature",
      type: 'scatter',
      marker: {color: COLORS.get('air_temperature')},
      yaxis: 'y1',
      hovertemplate: '%{y} ' + UNITS.get('air_temperature')
  };

  var water_temperature_trace: Data = {
      x: time_stamps_date,
      y: water_temperature,
      name: "Water Temperature",
      type: 'scatter',
      marker: {color: COLORS.get('water_temperature')},
      yaxis: 'y1',
      hovertemplate: '%{y} ' + UNITS.get('water_temperature')
  };

  var dew_point_trace: Data = {
      x: time_stamps_date,
      y: dewpoint,
      name: "Dew Point",
      type: 'scatter',
      marker: {color: COLORS.get('dew_point')},
      yaxis: 'y1',
      hovertemplate: '%{y} ' + UNITS.get('dew_point')
  };

  var wind_speed_trace: Data = {
      x: time_stamps_date,
      y: wind_speed,
      name: "Wind Speed",
      type: 'scatter',
      marker: {color: COLORS.get('wind_speed')},
      yaxis: 'y2',
      hovertemplate: '%{y} ' + UNITS.get('wind_speed')
  };

  var gusts_of_wind_trace: Data = {
      x: time_stamps_date,
      y: gusts_of_wind,
      name: "Gusts of Wind",
      type: 'scatter',
      marker: {color: COLORS.get('gusts_of_wind'), width: 0.5},
      line: {color: COLORS.get('gusts_of_wind'), dash: 'dash'},
      yaxis: 'y2',
      hovertemplate: '%{y} ' + UNITS.get('gusts_of_wind')
  };

  var air_pressure_trace: Data = {
      x: time_stamps_date,
      y: air_pressure,
      name: "Air Pressure",
      type: 'scatter',
      marker: {color: COLORS.get('air_pressure'), width: 1},
      yaxis: 'y3',
      hovertemplate: '%{y} ' + UNITS.get('air_pressure')
  };

  var precipitation_amount_trace: Data = {
      x: time_stamps_date,
      y: precipitation_amount,
      name: "Rain",
      type: 'scatter',
      marker: {color: COLORS.get('rain'), width: 1},
      yaxis: 'y4',
      hovertemplate: '%{y} ' + UNITS.get('rain')
  };

  var wind_direction_trace: Data = {
      x: time_stamps_date,
      y: wind_direction,
      name: "Wind Direction",
      type: 'scatter',
      marker: {color: COLORS.get('wind_direction'), width: 1},
      line: {color: COLORS.get('wind_direction'), dash: 'dot'},
      yaxis: 'y5',
      hovertemplate: '%{y}' + UNITS.get('wind_direction')
  };

  var solar: Data = {
      x: time_stamps_date,
      y: radiation,
      name: "Solar Radiation",
      type: 'scatter',
      marker: {color: COLORS.get('solar'), width: 1},
      yaxis: 'y6',
      hovertemplate: '%{y} ' + UNITS.get('solar')
  };

  var data = [air_temperature_trace, water_temperature_trace, dew_point_trace,
      wind_speed_trace, gusts_of_wind_trace, wind_direction_trace,
      air_pressure_trace, precipitation_amount_trace, solar];

  var layout: Partial<Layout> = {
      title: '',
      hovermode: 'x',
      xaxis: {
          domain: [0.15, 0.85],
          showline: true,
          linewidth: 1,
          linecolor: 'grey',
      },
      yaxis: {
          title: 'Temperature [ºC]',
          titlefont: {
              color: 'black'
          },
          tickfont: {
              color: 'black'
          },
          range: LIMITS.get('temperature'),
          zeroline: false,
          showline: true,
          linecolor: 'grey',
          tickwidth: 1,
          ticks: 'outside'
      },
      // Speed Axis
      yaxis2: {
          title: 'Speed [kts]',
          titlefont: {
              color: 'black'
          },
          tickfont: {
              color: 'black'
          },
          showline: true,
          linecolor: 'grey',
          range: LIMITS.get('speed'),
          zeroline: false,
          tickwidth: 1,
          ticks: 'outside',
          anchor: 'free',
          overlaying: 'y',
          side: 'right',
          position: 0.9
      },
      // Air Pressure Axis
      yaxis3: {
          title: 'Pressure [hPa]',
          titlefont: {
              color: 'black'
          },
          tickfont: {
              color: 'black'
          },
          showline: true,
          linecolor: 'grey',
          range: LIMITS.get('air_pressure'),
          zeroline: false,
          tickwidth: 1,
          ticks: 'outside',
          anchor: 'free',
          overlaying: 'y',
          side: 'left',
          position: 0.1
      },
      // Precipitation Axis
      yaxis4: {
          title: 'Amount [l/m²]',
          titlefont: {
              color: 'black'
          },
          tickfont: {
              color: 'black'
          },
          showline: true,
          linecolor: 'grey',
          range: LIMITS.get('rain'),
          zeroline: false,
          tickwidth: 1,
          ticks: 'outside',
          anchor: 'free',
          overlaying: 'y',
          side: 'left',
          position: 0.05
      },
      // Direction Axis
      yaxis5: {
          title: 'Direction [Deg]',
          titlefont: {
              color: 'black'
          },
          tickfont: {
              color: 'black'
          },
          showline: true,
          linecolor: 'grey',
          range: LIMITS.get('wind_direction'),
          zeroline: false,
          tickwidth: 1,
          ticks: 'outside',
          anchor: 'free',
          overlaying: 'y',
          side: 'right',
          position: 0.85
      },
      // Solar Axis
      yaxis6: {
          title: 'Energy [W/m²]',
          titlefont: {
              color: 'black'
          },
          tickfont: {
              color: 'black'
          },
          showline: true,
          linecolor: 'grey',
          range: LIMITS.get('solar'),
          zeroline: false,
          tickwidth: 1,
          ticks: 'outside',
          anchor: 'free',
          overlaying: 'y',
          side: 'right',
          position: 0.95
      }

  }
  return(
    <Plot data={data} layout={layout} className="weather-plot center"/>
  );
};

function App() {
  return (
    <div className="App">
      <a href="/">
      <header className="App-header">
        <h1>Weather Dashboard</h1>
      </header>
      </a>
      <body>
      <div>
      </div>
      <div className='div-weather-element'>
        <StationView />
      </div>
      </body>
    </div>
  );
}

export default App;
